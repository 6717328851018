import { Link } from "gatsby"
import React from "react"
import Seo from "../components/seoPage"
import Bread from "../components/breadPage"
const Diclamer = () => {
  return (
    <main>
      <Seo
        title="Tentang hajebo blog"
        diskripsi="Tentang haje blog kenapa bisa bediri dan kaitannya dengan tulisan dan konten hajebo"
        slug="/about/"
        tags="tentang hajebo, about"
        imageUrl="https://hajebo.com/logo.png"
      />
      <Bread nama2="about" />
      <div className="mx-4 my-8 md:my-20 md:mx-10 lg:mx-20">
        <h1 className="text-center m-8 md:m-10 text-3xl font-extrabold">
          About
        </h1>
        <div className="text-lg md:text-2xl">
          <p>
            <Link className="font-bold" to="/">
              Hajebo.com
            </Link>{" "}
            adalah blok yang kamu buat sebagai media sharing pengalaman berbagai
            topik yang bisa menambah wawasan. Saya adalah pecinta kucing yang
            hobi masak dan suka traveling. Saya berasal dari kota pati di jawa
            tengah yang memiliki daerah pantai dan gunung. Saya mulai membuat
            blog selama pandemi covid-19 untuk mengisi waktu luang sembari agar
            tidak bosan di rumah. saya juga berharap pengalaman yng saya bagikan
            bisa berguna untuk para pembaca sekalian.
          </p>
        </div>
      </div>
    </main>
  )
}

export default Diclamer
